import React, { useEffect } from "react"
import { parse } from "query-string"

import { upgradeToManual } from "../../utils/CallAPIs"

export default function RedirectSuccess() {
  useEffect(() => {
    const asyncAction = async () => {
      const { subscription_id: subscription } = parse(window.location.search)

      await upgradeToManual(subscription)
      window.location.replace("/proxies/manage?id=" + subscription)
    }

    asyncAction()
  }, [])

  return <div />
}
